
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay]);

document.addEventListener('DOMContentLoaded',
	function () {
		const appears = document.querySelectorAll(".appear");
		const options = {
			rootMargin: '0px',
			// threshold: 1.0
		}
		let observe = new IntersectionObserver(callback, options);
		appears.forEach(function (value) {
			observe.observe(value);
		})

		function callback(entries) {
			for (var i = 0; i < entries.length; i++) {
				if (entries[i].isIntersecting) {
					entries[i].target.classList.remove("appear");
				} else {
					// entries[0].target.classList.remove("appears");
				}
			}
		}

		const header = document.querySelector("#header");
		let start_position = 100;
		let window_position;

		window.addEventListener("scroll", () => {
			window_position = window.scrollY;
			const headerHeight = header.offsetHeight;

			if (window_position <= start_position) {
				header.style.top = "0";
			} else {
				header.style.top = `-${headerHeight}px`;
			}

			start_position = window_position;
		});


		//
		const swiper = new Swiper('.swiper', {
			loop: true,
			centeredSlides: true,
			speed: 1000,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false
			}
		});


		//

		const spbtn = document.querySelector(".sd[data-s-fe4ff117-0698-4a54-8f33-82e44d1b42e3]");
		const splink = document.querySelector(".sd[data-s-348159c8-8f26-4e87-9880-90e5db66d1f2] a");
		const close = document.querySelector(".sd[data-s-bf469567-fa2a-48b5-86a4-f72ccffe458e]");
		const modals = document.querySelector(".modals");

		spbtn.addEventListener("click", () => {
			modals.classList.add("open");
		})

		close.addEventListener("click", () => {
			modals.classList.remove("open");
		})
	}
);
